(function($) {

  /**
   * Make the necessary toolbar adjustments to the #top region, which is fixed position
   */
  Drupal.behaviors.toolbarAdjustments = {
    doAdjustments: function() {
      var e = Drupal.cl.getElements();
      // NOTE: the sticky bar will place the e.top at -115px when in effect - so we check for this before re-adjusting
      if (e.body.hasClass('toolbar') && e.top.css('position') == 'fixed' && e.top.css('top') != '-115px') {
        var h = Drupal.cl.getToolbarHeight();

        if (h > 0) {
          e.top.css({ 'top': h + 'px' });
        }
      }
    },

    init: function() {
      var self = this;

      self.doAdjustments();
      $(window).resize(function() {
        self.doAdjustments();
      });
    },

    attach: function(context, settings) {
      this.init();
    }
  };


  /**
   * Set the top of the #main element if/when #top if fixed
   */
  Drupal.behaviors.fixedHeaderAdjustments = {
    doAdjustments: function() {
      var e = Drupal.cl.getElements();
      var properties = {};

      // Only set padding on #main when #top has fixed position
      if (e.top.css('position') == 'fixed') {
        var h = Drupal.cl.getTopHeight();

        // The toolbar already adds a padding-top to the body element, which means
        // I don't need account for it's height manually.
        // if (e.body.hasClass('toolbar')) {
        //   h += Drupal.cl.getToolbarHeight();
        // }

        properties['padding-top'] = h + 'px';
      }

      // IE-friendly way to only apply properties when they exist
      var length = 0;
      for (prop in properties) {
        if (properties.hasOwnProperty(prop)) {
          length++;
        }
      }
      if (length > 0) {
        e.main.css( properties );
      }
    },

    init: function() {
      var self = this;

      self.doAdjustments();
      $(window).resize(function() {
        self.doAdjustments();
      });
    }

    /*
    attach: function(context, settings) {
      this.init();
    }
    */
  };

  /**
   * CAPS LOCK CHECKER/NOTIFIER
   * USES THIS PLUGIN https://github.com/azanov/jquery.capsChecker
   */
  Drupal.behaviors.capsChecker = {
    doAdjustments: function() {
      var textElements = $('.form-text');
      textElements.each(function() {
        var height = $(this).css('height');
        var width = $(this).width();
        var name = $(this).attr('name');
        var capsWarning = $('<span class="caps-warning" for="' + name + '">&#8682;</span>').css({
          'cursor': 'pointer',
          'display': 'none',
          'height': height,
          'line-height': height,
          'margin-left': width + 'px',
          'pointer-events': 'none',
          'position': 'absolute'
        });
        $(this).before(capsWarning);
      });

      textElements.capsChecker({
        capson: function(e, isOn) {
          $(this).siblings('.caps-warning').show();
        },
        capsoff: function(e, isOn) {
          $(this).siblings('.caps-warning').hide();
        }
      });
    },

    init: function() {
      var self = this;
      self.doAdjustments();
    },

    attach: function(context, settings) {
      this.init();
    }
  };

  /**
   * Apply placeholder plugin to input elements whose have placeholder attributes, to work around IE 8
   * and below limitations.
   * https://github.com/mathiasbynens/jquery-placeholder
   */
  Drupal.behaviors.placeholderPolyfill = {
    attach: function(context, settings) {
      $(document).ready(function() {
        var elements = $('input[placeholder], textarea[placeholder]');
        elements.placeholder();
      });
    }
  };

  /**
   * Apply textfill plugin to elements whose text should fill the dimensions of their parent container
   * http://stackoverflow.com/questions/687998/auto-size-dynamic-text-to-fill-fixed-size-container
   */
  Drupal.behaviors.bigText = {
    attach: function(context, settings) {
      $(document).ready(function() {
        var elements = $('.textfill');
        elements.each(function() {
          $(this).textfill({
            maxFontPixels: 36
          });
        });
      });
    }
  };

  Drupal.behaviors.isIpad = navigator.userAgent.match(/iPad/i) != null;

  Drupal.behaviors.launchBlueOcean = {
    // Presumably this method can be overwritten by a locale
    getPath: function() {
      return '/blue_ocean/quiz_overlay.tmpl';
    },

    launchBlueOcean: function(path) {
      var width = '780px',
          height = '525px',
          obHeight = 525;

      var overlayClass = 'ob_overlay';
      if (Drupal.behaviors.isIpad && $('html').width() < 962) {
        width = '760px';
        overlayClass = 'ob_overlay ipad_ob_overlay';
      }

      generic.template.get({
        path: path,
        forceReload: true,
        callback: function(html) {
          generic.overlay.launch({
            content: html,
            cssStyle: {width: width, height: height},
            cssClass: overlayClass
          });

          // Move the close icon based on viewport height
          var viewportHeight = $(window).height();
          var bufferHeight = 5;
          if (obHeight - viewportHeight > 0) {
            var moveCloseIcon = (obHeight - viewportHeight)/2 + bufferHeight + 'px';
            $('.ob_overlay .close-container').css('top', moveCloseIcon);
          }
        }
      });
    },

    attach: function(context, settings) {
      var self = this;

      $('.blueOceanQuizButton', context).once('bo-button-overlay').on('click', function(e) {
        if (Drupal.settings.globals_variables.gdpr_compliant && $(e.target).hasClass('tools-pages')) {
          e.preventDefault();
          return;
        }
        var path = $(this).attr('href');
        if (typeof path === 'undefined' || path == '') {
          path = self.getPath();
        }

        self.launchBlueOcean(path);
        return false;
      });
    }
  };

  Drupal.behaviors.launchFoundationFinder = {
    // Presumably this method can be overwritten by a locale
    getPath: function() {
      return '/foundation_finder/frame.tmpl';
    },

    launchFoundationFinder: function(path) {
      generic.template.get({
        path: path,
        forceReload: true,
        callback: function(html) {
          generic.overlay.launch({
            content: html,
            cssStyle: { width: 'auto', height: 'auto' },
            cssClass: 'ff_overlay'
          });
        }
      });
    },

    attach: function(context, settings) {
      var self = this;
      var path = this.getPath();

      $('.foundationFinderQuizButton', context).once('ff-button-overlay').on('click', function(e) {
        if (Drupal.settings.globals_variables.gdpr_compliant && $(e.target).hasClass('tools-pages')) {
          e.preventDefault();
          return;
        }
        self.launchFoundationFinder(path);
        return false;
      });
    }
  };

  // Basic/dumb older IE "polyfill"/stub, to un-break older versions of IE when console.log is called
  // JGA - I'm not sure why this is here when it already exists in the shared-main.js file
  if (!window.console) {
    window.console = {
      log: function () {}
    };
  }

  /**
   * Adjust footer CSS bottom property to auto when browser is resized
   */
  Drupal.behaviors.footerAdjustments = {
    hasRun: false,

    init: function() {
      function doAdjustments() {
        var $window = $(window),
            e = Drupal.cl.getElements(),
            $f = e.footer,
            winHeight = $window.height(),
            // default value was 65, adding extra buffer for Checkout Signin page w/ Social Login as per ACAC-5100
            extraBuffer = document.querySelector('body#signin #content .social-login') ? 225 : 65,
            minWindowHeight = $('#top_wrapper').outerHeight(true) + $('#wrapper, #main').outerHeight(true) + extraBuffer;
            // 220 is a buffer, the height of the expanded footer

        if (winHeight <= minWindowHeight) {
          $f.addClass('bottom-auto');
        }
        else {
          $f.removeClass('bottom-auto');
        }
      }

      var _doAdjustments = _.throttle(doAdjustments, 200);

      doAdjustments();
      $(window).resize(_doAdjustments);
    },

    attach: function(context, settings) {
      if (!this.hasRun) {
        this.init();
        this.hasRun = true;
      }
    }
  };

  Drupal.behaviors.msgOverlays = {
    attach: function(context, settings) {
      $(document).on('addToCart.success', function(e, cartResult) {
        var overlay_content = cartResult.ac_results && cartResult.ac_results.length ? cartResult.ac_results[0].result.OVERLAY : undefined;

        if (typeof overlay_content != 'undefined') {
          // pause for a split second so the overlay will fire properly after the cart overlay
          setTimeout(function(){
            generic.overlay.launch({
              content: overlay_content,
              includeBackground: true,
              cssClass: 'offer-overlay'
            });

            // if this has fired, it means they added something from the overlay (clicks outside the overlay would close this anyway)
            $('.overlay-container').on('click', function() {
              generic.overlay.hide();
            });
          }, 500);
        }
      });
    }
  }
})(jQuery);
